import React, { useState, useEffect } from "react";
import 'chart.js/auto';
import 'chartjs-adapter-moment';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartLine, faXmark } from '@fortawesome/free-solid-svg-icons'
import { IconButton } from "@mui/material";

//Registering plugin
Chart.register(annotationPlugin);

const configureChartData = (givenData, dataPoint, chartName) => {
    const config = {
        data: {
            datasets: [{
                borderWidth: 3,
                radius: 0,
                borderColor: 'rgb(94, 185, 255)',
                data: givenData,
                parsing: {
                    yAxisKey: chartName,
                    xAxisKey: 'Time'
                }
            }]
        },
        options: {
            scales: {
                xAxisKey: {
                    ticks: {
                        callback: (val, idx) => null
                    }
                },
                yAxisKey: {

                }
            },
            plugins: {
                legend: {
                    display: false
                },
                autocolors: false,
                annotation: {
                    annotations: {
                        line1: {
                            type: 'line',
                            borderColor: 'rgb(255, 99, 132)',
                            borderWidth: 2,
                            scaleID: 'xAxisKey',
                            value: givenData[dataPoint].Time
                        }
                    }
                }
            }
        }
    }
    if (chartName == "BatteryVoltage") config.options.scales.yAxisKey.min = 0
    if (chartName == "StateOfCharge") {
        config.options.scales.yAxisKey.min = 0
        config.options.scales.yAxisKey.max = 100
    }
    return config
}

const VehicleDataCard = ({ title, data, dataPoint, chartName, dataString }) => {
    const [displayChart, setDisplayChart] = useState(false)
    const chartObject = configureChartData(data, dataPoint, chartName)

    return (
        <div className="flex flex-col ">
            <div className="h-10 w-full  bg-slate-700 flex justify-between items-center">
                <div></div>
                <h2 className="  text-xl text-white">{title}</h2>
                <IconButton onClick={() => setDisplayChart(!displayChart)}>
                    {(displayChart) ?
                        <FontAwesomeIcon color={"white"} icon={faXmark} />
                        :
                        <FontAwesomeIcon color={"white"} icon={faChartLine} />
                    }
                </IconButton>

            </div>
            <div className="p-2 flex justify-center items-center">
                {(displayChart) ?
                    <div className="flex flex-col">
                        <p className="text-center">{dataString}</p>
                        <Line data={chartObject.data} options={chartObject.options} />
                    </div>
                    :
                    <div className="flex flex-col">
                        <p>Current</p>
                        <p className="text-center">{dataString}</p>
                    </div>
                }

            </div>
        </div>

    )
}

export default VehicleDataCard