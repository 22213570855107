import React, { useState, useEffect, useRef } from "react";
import "./App.css";

import { getMachines } from "../api/getMachines"
import TitleBar from "./TitleBar"
import DashboardPage from "./DashboardPage";
import ExportPage from "./ExportPage"
import LoginPage from "./LoginPage";
import { checkLogin } from "../api/checkLogin";
import Dashboard2Page from "./Dashboard2Page";
import VehiclePage from "./VehiclePage";
import KozakGPSPage from "./KozakGPSPage";

//const machineList = []

const pwMachine = "tel1999pwbbaafrodm3ota2" //UPS idx:1
const jwMachine = "tel1999jwaeaafrodm3ota2" //Air Canada idx:3
const jgMachine = "tel1999jgbaaafrodm3ota2" //JetBlue idx:4

const App = () => {
  const [page, setPage] = useState("Dashboard2")
  const [machineList, setMachineList] = useState([])
  const [isLoggedIn, setLogIn] = useState(null)
  const machineIdx = useRef(1)


  useEffect(() => checkLogin().then((r) => setLogIn(r)), [])

  //fetches the table names on init load of the website
  useEffect(() => {
    if (isLoggedIn === null || isLoggedIn === false) return
    getMachines().then((result) => {
      setMachineList(result)
    })
  }, [isLoggedIn])

  if (isLoggedIn === null) return (
    <>
    </>
  )

  return (
    (isLoggedIn) ?
      <>
        <TitleBar setPage={setPage} page={page} />
        {(() => {
          switch (page) {
            case "Dashboard": return <DashboardPage machineList={machineList} />
            case "Export": return <ExportPage machineList={machineList} />
            case "Dashboard2": return <Dashboard2Page machineList={machineList} setPage={setPage} page={page} machineIdx={machineIdx} />
            case "VehiclePage": return <VehiclePage machine={machineList[machineIdx.current]} setPage={setPage}/>
            case "Kozak_GPS": return <KozakGPSPage setPage={setPage}/>
            default: return
          }
        })()}
        {/* {(page === "Dashboard") ? <DashboardPage machineList={machineList} /> : <ExportPage machineList={machineList} />} */}
      </>
      :
      <>
        <LoginPage setLogIn={setLogIn} />
      </>

  )
}

export default App