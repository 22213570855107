import React, { useEffect, useState } from "react";
import L, { map } from 'leaflet'
import { MapContainer, TileLayer, CircleMarker, Tooltip, MapConsumer } from 'react-leaflet'

// const fleetData = [
//     { id: 1, power: 'On', vehicle: 'UPS MA 2728', SOC: 80, runningHours: 35, coord: [38.173204, -85.731437] },
//     { id: 2, power: 'On', vehicle: 'UPS MA 3985', SOC: 70, runningHours: 42, coord: [38.163254, -85.741417] },
//     { id: 3, power: 'Off', vehicle: 'UPS MA 1278', SOC: 44, runningHours: 45, coord: [38.163284, -85.731437] },
//     { id: 4, power: 'On', vehicle: 'UPS MA 2985', SOC: 98, runningHours: 16, coord: [38.173284, -85.741437] },
//     { id: 5, power: 'Off', vehicle: 'UPS MA 4211', SOC: 53, runningHours: 45, coord: [38.170284, -85.738437] },
//     { id: 6, power: 'Off', vehicle: 'UPS MA 9820', SOC: 92, runningHours: 150, coord: [38.170284, -85.734437] },
//     { id: 7, power: 'On', vehicle: 'UPS MA 3321', SOC: 30, runningHours: 44, coord: [38.170284, -85.740437] },
//     { id: 8, power: 'On', vehicle: 'UPS MA 5647', SOC: 59, runningHours: 36, coord: [38.175284, -85.740437] },
//   ];

const airport = {
    worldPortSDF: {
        coord: [38.167525, -85.734995],
        data: [
            [38.173204, -85.731337],
            [38.163254, -85.741217],
            [38.163284, -85.731137],
            [38.173284, -85.741737],
            [38.170284, -85.738637],
            [38.170384, -85.734437],
            [38.170684, -85.740437],
            [38.1752784, -85.740437]
        ]
    },
    westernHubONT: {
        coord: [34.056520, -117.600907],
        data: [
            [34.058520, -117.601907],
            [34.058520, -117.603907],
            [34.057020, -117.600907],
            [34.057020, -117.602907],
            [34.057020, -117.590907],
            [34.050520, -117.580807],
            [34.051520, -117.577707],
            [34.056520, -117.600607],
        ]
    },
    torontoPearsonYYZ: {
        coord: [43.678643, -79.629850],
        data: [
            [43.670643, -79.629850],
            [43.675643, -79.624990],
            [43.680643, -79.635550],
            [43.675643, -79.629850],
            [43.679643, -79.629850],
            [43.677643, -79.629850],
            [43.673643, -79.633850],
            [43.678643, -79.629850]
        ]
    },
    vancouverYVR: {
        coord: [49.195335, -123.181321],
        data: [
            [49.185235, -123.181321],
            [49.200135, -123.180021],
            [49.195035, -123.171321],
            [49.195435, -123.191321],
            [49.190535, -123.197921],
            [49.188335, -123.172321],
            [49.195335, -123.181121],
            [49.195335, -123.181521],
        ]
    },
    atlantaATL: {
        coord: [33.640607, -84.432412],
        data: [
            [33.625507, -84.432412],
            [33.630407, -84.432412],
            [33.628707, -84.433412],
            [33.626807, -84.437412],
            [33.627207, -84.425312],
            [33.629997, -84.425212],
            [33.631607, -84.424512],
            [33.630607, -84.421612],
        ]
    }
}

const FleetMap = ({ setSelectedVehicle, fleetData, selectedVehicle, site }) => {
    //the real one UPS MA 2728


    return (
        <MapContainer center={airport[site].coord} zoom={15} scrollWheelZoom={true}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {fleetData.map((el, idx) => <CircleMarker pathOptions={{
                color: (el.vehicle === selectedVehicle.vehicle) ? "green" :
                    (el.vehicle === "UPS MA 2728") ? "blue" : "red"
            }}
                center={airport[site].data[el.id - 1]} key={idx} radius={8} eventHandlers={{
                    click: () => setSelectedVehicle(el)
                }}>
                <Tooltip >{el.vehicle}</Tooltip>
            </CircleMarker>)}
            <MapConsumer>
                {(map) => {

                    //console.dir(coordArray)
                    const latLon = L.latLngBounds(airport[site].data)
                    //console.log("LatLon", latLon)
                    map.fitBounds(latLon)
                    return null
                }}
            </MapConsumer>

        </MapContainer>
    )
}

export default FleetMap