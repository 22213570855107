import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { Box } from "@mui/system";
import { CircularProgress, Typography, IconButton } from "@mui/material";
import RestartAltSharpIcon from '@mui/icons-material/RestartAltSharp';
import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';
import StopSharpIcon from '@mui/icons-material/StopSharp';
import PauseSharpIcon from '@mui/icons-material/PauseSharp';
import FastRewindSharpIcon from '@mui/icons-material/FastRewindSharp';
//import FastForwardSharpIcon from '@mui/icons-material/FastForwardSharp';
import OndemandVideoSharpIcon from '@mui/icons-material/OndemandVideoSharp';

import { getMapData } from "../api/getMapData";
import L, { map } from 'leaflet'
import { MapContainer, TileLayer, MapConsumer, CircleMarker, Popup } from 'react-leaflet'
import FastForwardSharp from "@mui/icons-material/FastForwardSharp";

const returnMapResults = (mapData, isLoading, setIsLooping) => {
    if (isLoading === true) return (
        <>
            {/* <Box sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "gray", opacity: "0.3" }} /> */}
            <Box sx={{ width: "47vw", height: "57vh", backgroundColor: "gray", opacity: "0.3" }} />
            <CircularProgress sx={{ position: "absolute", top: "50%", left: "50%" }} />
        </>
    )
    if (mapData.length === 0) return (
        <Box sx={{ width: "47vw", height: "57vh", display: "flex", justifyContent: "center" }}>
            <Typography sx={{ alignSelf: "center" }}>No Map Results</Typography>
        </Box>
    )
    return (
        <>
            <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {(mapData.length === 0) ?
                    ""
                    :
                    mapData.map((el, idx) => <CircleMarker center={el.coord} key={idx} radius={2}>
                        <Popup>Time: {moment(el.time).format("lll")}</Popup>
                    </CircleMarker>)
                }
                {(mapData.length === 0) ?
                    ""
                    :
                    <MapConsumer>
                        {(map) => {
                            const coordArray = mapData.reduce((prev, curr) => {
                                prev.push(curr.coord)
                                return prev
                            }, [])
                            //console.dir(coordArray)
                            const latLon = L.latLngBounds(coordArray)
                            bounds = latLon
                            //console.log("LatLon", latLon)
                            map.fitBounds(latLon)
                            return null
                        }}
                    </MapConsumer>
                }
            </MapContainer>
            <IconButton onClick={() => setIsLooping(true)}><OndemandVideoSharpIcon /></IconButton>
        </>
    )
}

let bounds = null

const loopThroughMap = (mapData, count, setCount, setIsLooping, isPaused, setIsPaused) => {

    const start = (count > 50) ? count - 50 : 0

    let loopData = mapData.slice(start, count)

    if (mapData.length === count) {
        console.log("Looping has stopped")
        setIsLooping(false)
        setTimeout(() => setCount(1), 100)
    }

    return(
        <>
        <MapContainer bounds={bounds}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {loopData.map((el, idx) => <CircleMarker center={el.coord} key={idx} radius={2}>
                    <Popup>Time: {moment(el.time).format("lll")}</Popup>
                </CircleMarker>)}
                { (!isPaused) ? setTimeout(() => {
                    //console.log("NewRender")
                    const newCount = count + 1
                    setCount(newCount)
                }, 50) : ""}
            </MapContainer>
            {(isPaused) ? 
                <>
                    <IconButton onClick={() => {
                        const newCount = count - 200
                        if (newCount <= 0) setCount(1)
                        else setCount(newCount)
                    }}><FastRewindSharpIcon /></IconButton>
                    <IconButton onClick={() => { setIsPaused(false)}}><PlayArrowSharpIcon /></IconButton>
                    <IconButton onClick={() => {
                        const newCount = count + 200
                        if (newCount > mapData.length) setCount(mapData.length - 1)
                        else setCount(newCount)
                    }}><FastForwardSharp /></IconButton>
                    <IconButton onClick={() => { setCount(1)}}><RestartAltSharpIcon /></IconButton>
                </> 
                : <IconButton onClick={() => { setIsPaused(true)}}><PauseSharpIcon /></IconButton>}
            <IconButton onClick={() => { setIsLooping(false)}}><StopSharpIcon /></IconButton>
            <Typography>{moment(loopData[loopData.length - 1]?.time).format("lll")}</Typography>
        </>

    )
}

const MyMap = ({ machine, date }) => {
    const [mapData, setMapData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isLooping, setIsLooping] = useState(false)
    const [isPaused, setIsPaused] = useState(false)
    const [count, setCount] = useState(1)

    useEffect(() => {
        setIsPaused(false)
        setCount(1)
        setIsLooping(false)
        setIsLoading(true)
        const utcDateString = moment(date).utc().format()
        getMapData(utcDateString, machine)
            .then((result) => setMapData(result))
            .finally(() => setIsLoading(false))
    }, [machine, date])

    return (isLooping) ? loopThroughMap(mapData, count, setCount, setIsLooping, isPaused, setIsPaused) : (returnMapResults(mapData, isLoading, setIsLooping))
}

export default MyMap