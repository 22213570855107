import React, { useState, useEffect } from "react";
import 'chart.js/auto';
import 'chartjs-adapter-moment';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation';
import moment from "moment-timezone";
import { Box } from "@mui/system";
import { CircularProgress, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";

import { getChart } from "../api/getChart";

//Registering plugin
Chart.register(annotationPlugin);

const charts = [
    "StateOfCharge",
    "ModuleVoltage",
    "ActualTorque",
    "SystemPowerMode",
    "HVBatteryISense",
    "HVBatteryVoltage",
    "RunHours",
    "OffBoardHVChargeVehicleStat"
]

const defaultChartData = {
    data: {
        datasets: [{
            label: "",
            backgroundColor: 'rgb(94, 185, 255)',
            borderWidth: 3,
            radius: 0,
            borderColor: 'rgb(94, 185, 255)',
            data: []
        }]
    },
    options: {
        scales: {
            x: {
                type: 'time',
            }
        }
    }
}

const addAdditionalChartConfigs = (configs, chartTypeArray) => {
    chartTypeArray.forEach((el, idx) => {
        if (el === null) return
        const yAxis = (idx === 0) ? 'y1' : 'y2'
        const dataSet = idx
        //console.log(configs.data.datasets[0].data[100].x)
        switch (el) {
            case "StateOfCharge":
                configs.options.scales[yAxis].min = 0
                configs.options.scales[yAxis].max = 100
                configs.options.plugins = {
                    autocolors: false,
                    annotation: {
                        annotations: {
                            line1: {
                                type: 'line',
                                scaleID: 'x',
                                value: configs.data.datasets[0].data[100].x,
                                borderColor: 'rgb(255, 99, 132)',
                                borderWidth: 2,
                            }
                        }
                    }
                }
                break;
            case "ModuleVoltage":
                configs.options.scales[yAxis].min = 0
                break;
            case "ActualTorque":
                //Values are given in 100s, Peters excel has it range from -22534 to 43000 Nm
                break;
            case "SystemPowerMode":
                configs.data.datasets[dataSet].stepped = true
                configs.options.scales[yAxis].title.display = false
                configs.options.scales[yAxis].ticks = {}
                configs.options.scales[yAxis].min = 0
                configs.options.scales[yAxis].max = 3
                configs.options.scales[yAxis].ticks.callback = (value, index, ticks) => {
                    if (value == 0) return "Off"
                    if (value == 1) return "Accessory"
                    if (value == 2) return "Run"
                    if (value == 3) return `Crank Request`
                }
                break;
            case "RunHours":
                configs.options.scales[yAxis].min = 0
                break;
            case "HVBatteryVoltage":
                //configs.options.scales[yAxis].min = 0
                break;
            case "OffBoardHVChargeVehicleStat":
                configs.data.datasets[dataSet].stepped = true
                configs.options.scales[yAxis].title.display = false
                configs.options.scales[yAxis].ticks = {}
                configs.options.scales[yAxis].min = 0
                configs.options.scales[yAxis].max = 9
                configs.options.scales[yAxis].ticks.callback = (value, index, ticks) => {
                    if (value == 0) return "No Data"
                    if (value == 1) return "Vehicle Not Ready"
                    if (value == 2) return "Vehicle Ready/Charging"
                    if (value == 3) return `Battery Temp Inhibit`
                    if (value == 4) return `Vehicle Shift Position`
                    if (value == 5) return `Charger Connector Lock Fault`
                    if (value == 6) return `Vehicle Cabin Conditioning`
                    if (value == 7) return `Vehicle Ress Conditioning`
                    if (value == 8) return `Vehicle Ress Malfunction`
                    if (value == 9) return `Battery Current`
                }
                break;
            default:
                break;
        }
    })
}

const Graph = ({ chartType, machine, date }) => {
    const [data, setData] = useState(defaultChartData)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedRadio, setSelectedRadio] = useState("none")

    const handleRadioChange = async (event) => {
        //console.dir(event)
        setSelectedRadio(event.target.value)
    }


    useEffect(() => {
        setIsLoading(true)
        setData(defaultChartData)
        const utcDateString = moment(date).utc().format()
        const secondChartType = (selectedRadio === 'none') ? null : selectedRadio
        getChart(utcDateString, chartType, machine, secondChartType)
            .then((result) => {
                result.options.scales.x.title.text = `Time Zone: ${moment.tz.guess()}`
                addAdditionalChartConfigs(result, [chartType, secondChartType])
                setData(result)
            })
            .finally(() => setIsLoading(false))
    }, [machine, date, chartType, selectedRadio])
    return (
        <>
            <Line data={data.data} options={data.options} />
            {(isLoading) ? <>
                <Box sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "gray", opacity: "0.3" }} />
                <CircularProgress sx={{ position: "absolute", top: "50%", left: "50%" }} />
            </> : ''}
            <FormControl>
                <FormLabel id="Chart-Radio">Multi Axis Line Chart Options</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="Chart-Radio"
                    name="chart-radio-button-group"
                    value={selectedRadio}
                    onChange={handleRadioChange}
                >
                    <FormControlLabel sx={{ margin: "0px 5px 0px 0px" }} labelPlacement="top" key="none" value="none" control={<Radio />} label={<Typography sx={{ fontSize: 12 }}>None</Typography>} />
                    {charts.map((el, idx) => { if (el !== chartType) return <FormControlLabel sx={{ margin: "0px 1vw 0px 0px" }} labelPlacement="top" key={idx} value={el} control={<Radio />} label={<Typography sx={{ fontSize: 12 }}>{el}</Typography>} /> })}
                </RadioGroup>
            </FormControl>
        </>
    )
}

export default Graph