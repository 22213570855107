import axios from "axios";

export async function checkLogin() {
  try {
    const token = localStorage.getItem("token")
    if (!token) return false
    
    const result = await axios.post("api/check", {token})

    return result.data;
  } catch (error) {
    console.dir(error);
  }
}