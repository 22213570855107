import React, { useMemo } from "react";
import 'chart.js/auto';
import 'chartjs-adapter-moment';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation';

//Registering plugin
Chart.register(annotationPlugin);

const configureChartData = (givenData, dataPoint) => {
    return {
        data: {
            datasets: [{
                borderWidth: 3,
                radius: 0,
                borderColor: 'rgb(94, 185, 255)',
                data: givenData,
                pointRadius: 0,
                parsing: {
                    yAxisKey: "StateOfCharge",
                    xAxisKey: 'Time'
                }
            }]
        },
        options: {
            spanGaps: true,
            animation: false,
            scales: {
                xAxisKey: {
                    ticks: {
                        callback: (val, idx) => null
                    }
                },
                yAxisKey: {
                    min: 0,
                    max: 100
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                autocolors: false,
                annotation: {
                    annotations: {
                        line1: {
                            type: 'line',
                            borderColor: 'rgb(255, 99, 132)',
                            borderWidth: 2,
                            scaleID: 'xAxisKey',
                            value: givenData[dataPoint].Time
                        }
                    }
                }
            }
        }
    }
}

const calcMinMax = (data) => {
    let min = Infinity
    let max = -Infinity

    data.forEach((el, idx) => {
        if (el.StateOfCharge == null) return
        const value = parseInt(el.StateOfCharge)
        if (value < min && value != 100) min = value
        if (value >= max) {
            max = value
            if (value > 99){
                console.log(value)
            }
        }
    })
    return { min, max }
}

const SOCChart = ({ data, dataPoint }) => {

    const minMaxObj = useMemo(() => calcMinMax(data), [data])

    const chartObject = configureChartData(data, dataPoint)

    return (
        <div className="flex flex-col row-span-2">
            <div className="w-full bg-[#4F5B75] text-xl place-items-center pt-2 pb-2">
                <h2 className="text-center text-xl text-white font-semibold">State Of Charge</h2>
            </div>
            <div className=" flex justify-around items-center h-10 w-full bg-[#DEDEDE] text-black gap-1">
                
                <div className="flex gap-4">
                    <p className="font-semibold text-lg pr-10">Current: {data[dataPoint].StateOfCharge}%</p>
                    <p className="font-semibold text-lg pr-10">Min: {minMaxObj.min}%</p>
                    <p className="font-semibold text-lg pr-10">Max: {minMaxObj.max}%</p>
                </div>
            </div>
            <div className="pl-2 pr-4 "><Line data={chartObject.data} options={chartObject.options} /></div>
        </div>
    )
}

export default SOCChart