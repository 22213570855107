import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff, faBatteryFull, faHourglass, faBatteryThreeQuarters, faBatteryQuarter, faBolt } from '@fortawesome/free-solid-svg-icons'

const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    {
        field: 'power',
        headerName: 'Power',
        width: 90,
        renderCell: (param) => {
            const isOnColor = (param.row.power === "On") ? 'green' : 'red'
            return (
                <div className="flex items-center gap-2">
                    <FontAwesomeIcon color={isOnColor} icon={faPowerOff} />
                    <p style={{color: isOnColor}}>{param.row.power}</p>
                </div>
            )
        }
    },
    {
        field: 'vehicle',
        headerName: 'Vehicle',
        width: 120,
    },
    {
        field: 'SOC',
        headerName: 'State Of Charge',
        width: 140,
        renderCell: (param) => {
            const {SOC} = param.row
            let icon;
            let color;
            if (SOC > 90) {icon = faBatteryFull; color = 'green'}
            else if (SOC > 75) {icon = faBatteryThreeQuarters; color = 'green'}
            else if (SOC > 50) {icon = faBolt; color = 'blue'}
            else if (SOC > 0) {icon = faBatteryQuarter; color = 'red'}
            return (
                <div className="flex items-center gap-2">
                    <FontAwesomeIcon color={color} icon={icon} />
                    <p style={{color: color}}>{SOC}%</p>
                </div>
            )
        }
    },
    {
        field: 'runningHours',
        headerName: 'Run Hours',
        width: 110,
        renderCell: (param) => {
            
            return(
                <div className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faHourglass} />
                    <p>{param.row.runningHours} hrs</p>
                </div>
            )
        }
    },
];

const VehicleDataGrid = ({fleetData, setPage, machineIdx}) => {

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <DataGrid
                rows={fleetData}
                columns={columns}
                pageSize={8}
                hideFooter
                onCellClick={(params, event) => {
                    if (params.row.vehicle === "M1A JB3985") {
                        machineIdx.current = 4
                        setPage("VehiclePage")
                    } else if (params.row.vehicle === "MA AC1278"){
                        machineIdx.current = 3
                        setPage("VehiclePage")
                    } else if (params.row.vehicle === "M1A PT7844"){
                        machineIdx.current = 2
                        setPage("VehiclePage")
                    } else if (params.row.vehicle === "MA U7P2S8") {
                        machineIdx.current = 1
                        setPage("VehiclePage")
                    } else if (params.row.vehicle === "T200") {
                        machineIdx.current = 0
                        setPage("VehiclePage")
                    }
                }}
            />
        </div>
    )
}

export default VehicleDataGrid