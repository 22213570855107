import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'alert', headerName: '', width: 70, 
      renderCell: (param) => {
          const isActive = (param.row.alert) ? 'red' : ''
          return(
            <FontAwesomeIcon color={isActive} icon={faTriangleExclamation} />
          )
      }  
    },
    { field: "vehicle", headerName: "Vehicle", width: 100 },
    { field: "message", headerName: "Message", width: 200 },
    { field: 'startTime', headerName: "Activated At", width: 140 },
    { field: 'endTime', headerName: "Resolved At", width: 140 },
]

const AlertsDataGrid = ({alerts}) => {

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <DataGrid
                rows={alerts}
                columns={columns}
                pageSize={8}
                hideFooter
            />
        </div>
    )
}

export default AlertsDataGrid