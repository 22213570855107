import axios from "axios";

export async function getChart(date, chartType, machine, secondChartType="") {
  try {
    const token = localStorage.getItem("token")
    //console.log('chartType' + chartType)
    console.log("machine type", machine)
    const url = `api/Chart?startDate=${date}&chartType=${chartType}&machine=${machine}${(secondChartType) ? `&secondChartType=${secondChartType}`: ""}`
    console.log(url)
    const result = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    //console.dir(result);
    return result.data;
  } catch (error) {
    console.log("Trouble fetching", chartType);
    console.dir(error);
    // test returning the Error message from an axios request
  }
}
