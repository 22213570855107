import React, { useState, useEffect } from "react";
import { saveAs } from "file-saver";
import moment from "moment-timezone";
import LoadingButton from '@mui/lab/LoadingButton'
import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';

import { getFile } from "../api/getFile"
import { Button } from "@mui/material";

const ExportButtons = ({ date, machine }) => {
    const [loadingTSV, setLoadingTSV] = useState(false)
    const [loadingCSV, setLoadingCSV] = useState(false)

    const handleExport = async (isTSV) => {
        (isTSV) ? setLoadingTSV(true) : setLoadingCSV(true)
        const utcStartDate = moment(date).utc().format()
        const utcEndDate = moment(date).add(1, "day").utc().format()
        try {
            const result = await getFile(utcStartDate, utcEndDate, machine, isTSV)
            const blob = new Blob([result], { type: "text/plain;charset=utf-8" })
            if (isTSV) saveAs(blob, "etug.plg")
            else saveAs(blob, "etug.csv")

        } catch (error) {
            console.dir(error)
        } finally {
            (isTSV) ? setLoadingTSV(false) : setLoadingCSV(false)
        }
    }

    const handleCSVExport = async () => await handleExport(false)
    const handleTSVExport = async () => await handleExport(true)

    return (
        <div className="mr-6 ml-[100px]">
            <LoadingButton
                sx={{ alignSelf: "start", marginRight: 5 }}
                onClick={handleTSVExport}
                endIcon={<FileDownloadSharpIcon />}
                loading={loadingTSV}
                loadingPosition="end"
                variant="contained"
            >
                Export TSV
            </LoadingButton>
            <LoadingButton
                sx={{ alignSelf: "start", marginRight: 5 }}
                onClick={handleCSVExport}
                endIcon={<FileDownloadSharpIcon />}
                loading={loadingCSV}
                loadingPosition="end"
                variant="contained"
            >
                Export CSV
            </LoadingButton>
            <Button variant="contained">Disable Unit</Button>
        </div>
    )
}

export default ExportButtons