import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

const TitleBar = ({ page, setPage }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => setAnchorEl(event.currentTarget)
    return (
        <>
            <div className="grid grid-cols-3 w-screen h-16 md:hidden bg-myBlue mb-4">
                <div className="col-start-1"></div>
                <img src="https://www.powertraincontrolsolutions.com/images/mobile_logo.svg" title="PCS" alt="PCS logo" className="col-start-2 justify-self-center place-self-center" />
                <div className="col-start-3 justify-self-end pr-4 text-white place-self-center">
                    <IconButton
                        id="hamburger-icon"
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        aria-controls={open ? 'hamburger-menu' : undefined}
                        color="inherit"
                        onClick={handleClick}>
                        <MenuIcon fontSize="large" />
                    </IconButton>
                    <Menu
                        id="hamburger-menu"
                        aria-labelledby="hamburger-icon"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    >
                        <MenuItem disabled={(page === "Dashboard") ? true : false}
                            sx={{ color: `${(page === "Dashboard") ? "blue" : ""}`, backgroundColor: `${(page === "Dashboard") ? "lightgray" : ""}` }}
                            onClick={() => { setAnchorEl(null); setPage("Dashboard") }}>
                            Dashboard
                        </MenuItem>
                        <MenuItem disabled={(page === "Export") ? true : false}
                            sx={{ color: `${(page === "Export") ? "blue" : ""}`, backgroundColor: `${(page === "Export") ? "lightgray" : ""}` }}
                            onClick={() => { setAnchorEl(null); setPage("Export") }}>
                            Export
                        </MenuItem>
                        <MenuItem disabled={(page === "Dashboard2") ? true : false}
                            sx={{ color: `${(page === "Dashboard2") ? "blue" : ""}`, backgroundColor: `${(page === "Dashboard2") ? "lightgray" : ""}` }}
                            onClick={() => { setAnchorEl(null); setPage("Dashboard2") }}>
                            Dashboard 2
                        </MenuItem>
                    </Menu>
                </div>
            </div>
            <div className="flex w-100 h-16 title">
                <div className="flex items-center gap-3 pl-10 pr-10 w-2/3 text-white text-[16px]">
                    <img className="h-8" src="/images/logo.png" title="PCS" alt="PCS logo" />&nbsp;|&nbsp;
                    <img className="h-8" src="/images/egse.png" title="PCS" alt="PCS logo" />
                    <p className="text-white text-[1.1rem] font-semibold">Telemetry  <span className="text-white text-[0.7rem] font-normal">v3.34</span></p>
                </div>
                {/* <div className="absolute top-0 left-10 w-36 h-20 p-1 bg-myBlue">
                    <img src="/images/logo.png" title="PCS" alt="PCS logo"  />
                </div> */}
                {/* <Typography sx={{ placeSelf: "center center", fontFamily: "sans-serif", fontWeight: "bold", fontSize: "20px", gridColumnStart: 2 }}>ETug Telemetry</Typography> */}
                <div className="flex items-center gap-3 pl-10 pr-10 w-1/3 justify-end">
                    <img className="h-8" src="/images/userIcon.png" title="User" alt="User Icon"/>
                    <p className="text-white cursor-pointer font-semibold">Dan Boucher</p>
                </div>
                {/* <Box sx={{ placeSelf: "center end", marginRight: 3, gridColumnStart: 3 }}>
                    <Button sx={{ color: `${(page === "Dashboard") ? "blue" : "white"}` }} onClick={() => {
                        if (page === "Dashboard") return
                        setPage("Dashboard")
                    }}>Dashboard</Button>
                    <Button sx={{ color: `${(page === "Export") ? "blue" : "white"}` }} onClick={() => {
                        if (page === "Export") return
                        setPage("Export")
                    }}>Export</Button>
                    <Button sx={{ color: `${(page === "Dashboard2") ? "blue" : "white"}` }} onClick={() => {
                        if (page === "Dashboard2") return
                        setPage("Dashboard2")
                    }}>Dashboard 2</Button>
                </Box> */}
            </div>
            
        </>
    )
}

export default TitleBar