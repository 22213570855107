import React, { useState, useEffect, useMemo } from "react";
import moment from "moment-timezone";
import L, { map } from 'leaflet'
import { MapContainer, TileLayer, MapConsumer, CircleMarker, Popup } from 'react-leaflet'

let bounds = null

const MyMap2 = ({ data, playBack, setPlayBack, dataPoint, setDataPoint }) => {
    const { isEnabled, isPlaying } = playBack
    let mapData;
    if (isEnabled) {
        const start = (dataPoint > 15) ? dataPoint - 15 : 0
        mapData = data.slice(start, dataPoint)
    } else {
        mapData = data
    }

    // if (mapData.length === dataPoint) {
    //     console.log("Looping has stopped")
    //     setIsLooping(false)
    //     setTimeout(() => setDataPoint(1), 100)
    // }

    return (
        <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={true}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {mapData.map((el, idx, arr) => {
                const pinColor = (arr.length - 1 === idx) ? "red" : "blue"
                return <CircleMarker pathOptions={{color: pinColor}} center={[el.Lat, el.Lon]} key={idx} radius={2}>
                    <Popup>Time: {moment(el.Time).format("lll")}</Popup>
                </CircleMarker>
            })}
            {(isPlaying) ? setTimeout(() => {
                //console.log("NewRender")
                const newCount = dataPoint + 1
                if (newCount === data.length){
                    setPlayBack({ ...playBack, isPlaying: false })
                    return
                }
                setDataPoint(newCount)
            }, 50) : ""}
            {(isEnabled) ?
                <></>
                :
                <MapConsumer>
                    {(map) => {
                        const coordArray = mapData.reduce((prev, curr) => {
                            prev.push([curr.Lat, curr.Lon])
                            return prev
                        }, [])
                        //console.dir(coordArray)
                        const latLon = L.latLngBounds(coordArray)
                        bounds = latLon
                        //console.log("LatLon", latLon)
                        map.fitBounds(latLon)
                        return null
                    }}
                </MapConsumer>
            }
        </MapContainer>
    )
}

export default MyMap2