import axios from "axios";

export async function getVehicleInfo(date, machine) {

    if (machine === "test") {
      try {
        const token = localStorage.getItem("token")
        const url = `api/T200Info?startDate=${date}`
        //console.log(url)
        const result = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
        return result.data;
      } catch (error) {
        console.log("Trouble fetching T200 Info");
        console.dir(error);
        return;
      }
    }

    try {
        const token = localStorage.getItem("token")

        const url = `api/VehicleInfo?startDate=${date}&machine=${machine}`
        //console.log(url)
        const result = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
    
        //console.dir(result);
        return result.data;
      } catch (error) {
        console.log("Trouble fetching VehicleInfo");
        console.dir(error);
        // test returning the Error message from an axios request
      }
}