import React, { useState } from "react";
import { Box } from "@mui/system";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LoadingButton from '@mui/lab/LoadingButton'
import { TextField, FormControl, InputLabel, Typography, NativeSelect } from "@mui/material";
import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';
import moment from "moment-timezone";
import { saveAs } from "file-saver";

import MachineList from "./MachineList"

import { getFile } from "../api/getFile"

const ExportPage = ({ machineList }) => {
    const [startDate, setStartDate] = useState(moment(new Date()).startOf('day'))
    const [loadingTSV, setLoadingTSV] = useState(false)
    const [loadingCSV, setLoadingCSV] = useState(false)
    const [machineId, setMachineId] = useState("")
    const [hours, setHours] = useState(24);

    const handleExport = async (isTSV) => {
        (isTSV) ? setLoadingTSV(true) : setLoadingCSV(true)
        const utcStartDate = moment(startDate).utc().format()
        const utcEndDate = moment(startDate).add(hours, "h").utc().format()
        try {
            const result = await getFile(utcStartDate, utcEndDate, machineId, isTSV)
            const blob = new Blob([result], { type: "text/plain;charset=utf-8" })
            if (isTSV) saveAs(blob, "etug.plg")
            else saveAs(blob, "etug.csv")

        } catch (error) {
            console.dir(error)
        } finally {
            (isTSV) ? setLoadingTSV(false) : setLoadingCSV(false)
        }
    }

    const handleCSVExport = async () => await handleExport(false)
    const handleTSVExport = async () => await handleExport(true)

    return (
        <div className="w-screen h-screen flex flex-col items-center">
            <Box >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Typography sx={{ textAlign: "center", textDecoration: "underline solid black 2px", marginBottom: 4, fontSize: 35 }}>Export File</Typography>
                    <Typography sx={{ textAlign: "center", marginBottom: 4, fontSize: 20 }}>Select Up to a 24hr Range</Typography>
                    <div className="flex flex-col justify-around w-[50vw] md:flex-row md:justify-items-center md:w-screen">
                            <MachineList machineList={machineList} machineId={machineId} setMachineId={setMachineId} />
                            <DateTimePicker
                                minDate={new Date('12-22-21')}
                                maxDate={new Date()}
                                clearable
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                renderInput={(params) => (
                                    <TextField {...params} helperText="Start Date" />
                                )}
                            />
                            <FormControl >
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Hours
                                </InputLabel>
                                <NativeSelect
                                    defaultValue={hours}
                                    inputProps={{
                                        name: 'age',
                                        id: 'uncontrolled-native',
                                    }}
                                    onChange={(event) => setHours(event.target.value)}
                                >
                                    {Array.from({ length: 24 }, (_, i) => i + 1).map(el => <option key={el} value={el}>{el}</option>)}
                                </NativeSelect>
                            </FormControl>
                            <LoadingButton
                                sx={{ alignSelf: "start" }}
                                onClick={handleTSVExport}
                                endIcon={<FileDownloadSharpIcon />}
                                loading={loadingTSV}
                                loadingPosition="end"
                                variant="contained"
                            >
                                Export TSV
                            </LoadingButton>
                            <LoadingButton
                                sx={{ alignSelf: "start" }}
                                onClick={handleCSVExport}
                                endIcon={<FileDownloadSharpIcon />}
                                loading={loadingCSV}
                                loadingPosition="end"
                                variant="contained"
                            >
                                Export CSV
                            </LoadingButton>
                    </div>
                </LocalizationProvider>
            </Box>
        </div>
    )
}

export default ExportPage