import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
//import * as serviceWorker from './serviceWorker.js';

import {
  App
} from './components';

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);

//serviceWorker.register()

// if ('serviceWorker' in navigator) {
//   // Use the window load event to keep the page load performant
//   window.addEventListener('load', () => {
//     console.log("Trying to register SW")
//     navigator.serviceWorker.register('serviceWorker.js')
//       .then(reg => console.log("Success: ", reg.scope)
//       .catch(err => console.log("Failure: ", err)));
//   });
// } else {
//   console.log("No service-worker on this browser")
// }