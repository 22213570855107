import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { logIn } from "../api/logIn";

const LoginPage = ({setLogIn}) => {
    const [username, setUserName] = useState("")
    const [password, setPassword] = useState("")
    const [flag, setFlag] = useState("")
    const handleLogIn = async () => {
        if (username.length === 0 || password.length === 0) return
        const res = await logIn(username, password)
        console.dir(res)
        if (res["token"]) {
          localStorage.setItem("token", res.token)
          setLogIn(true)
        }
        if (res["status"] === 400){
          setFlag("Your login credentials could not be verified, please try again."); 
        }
        
    }
    const handleLoginKey = async(event) => {
      if(event.key === 'Enter'){
        handleLogIn()
      }
    }
    return(
        <div className="flex w-screen h-screen justify-center items-center">
          <div className="border flex flex-col justify-center gap-8">
            <Typography fontSize={30}>Login</Typography>
            <TextField required size="small" placeholder="Username" label="Username" type={"text"} onChange={(event) => setUserName(event.target.value)}></TextField>
            <TextField required size="small" placeholder="Password" label="Password" type={"password"} onChange={(event) => setPassword(event.target.value)} onKeyPress={handleLoginKey} ></TextField>
            
            <Button onClick={handleLogIn} >Submit</Button>
            <p className="text-red-600">{flag}</p>
          </div>
        </div>
    )
}

export default LoginPage;
