import React, { useState } from "react";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import "./App.css";
import FastRewindSharpIcon from '@mui/icons-material/FastRewindSharp';
import FastForwardSharpIcon from '@mui/icons-material/FastForwardSharp';


import moment from "moment-timezone";
import { Select, InputLabel, FormControl, MenuItem, ToggleButton, ToggleButtonGroup, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import Graph from "./Graph";
import MyMap from "./MyMap"
import MachineList from "./MachineList"


const machineNames = {
  'tel1999pwbbaafrodm3ota2': "UPS",
  'tel1999mabkaafrodm3ota2': "Bench Unit 1",
  'tel1999jgbaaafrodm3ota2': "Bench Unit 2"
}

//Sate outside of React function to keep track of UI parts for fetch requests
let machine = ""

const DashboardPage = ({ machineList }) => {
  const [tempDate, setTempDate] = useState(moment(new Date()).startOf("day"))
  const [date, setDate] = useState(moment(new Date()).startOf("day"));
  //const [machineIds, setMachineIds] = useState([])
  const [machineId, setMachineId] = useState("")
  const [selectedCharts, setSelectedCharts] = useState(() => null)
  const [chart, setChart] = useState("")

  const handleDateChange = (newValue) => setTempDate(moment(newValue).startOf('day'))

  const handleSubmit = () => setDate(moment(tempDate))

  const forward12Hr = () => {
    const newDate = moment(date).add(12, "hours")
    setDate(newDate)
  }

  const back12Hr = () => {
    const newDate = moment(date).subtract(12, "hours")
    setDate(newDate)
  }

  const updateSelectedCharts = (event, newCharts) => {
    //Limit the # of charts that get displayed to only 4
    if (newCharts.length === 5) return
    //console.log(newCharts)
    setSelectedCharts(newCharts)
  }

  return (
    <>
      {/* Mobile */}
      <div className="md:hidden">
        <div className="flex justify-between pl-4">
          <MachineList machineList={machineList} machineId={machineId} setMachineId={setMachineId} />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="flex flex-row">
              <IconButton onClick={back12Hr}><FastRewindSharpIcon /></IconButton>
              <div className="w-32">
                <MobileDatePicker
                  label="Start Date"
                  inputFormat="MM/dd/yyyy"
                  value={date}
                  minDate={new Date('12-22-21')}
                  maxDate={new Date()}
                  onChange={handleDateChange}
                  onAccept={handleSubmit}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
              <IconButton onClick={forward12Hr}><FastForwardSharpIcon /></IconButton>
            </div>
          </LocalizationProvider>
        </div>
        <div className="m-4">
          <FormControl fullWidth>
            <InputLabel id="chart-type">Chart/Map</InputLabel>
            <Select
              labelId="chart-type"
              id="demo-simple-select"
              value={chart}
              label="Chart/Map"
              onChange={(event) => setChart(event.target.value)}
            >
              <MenuItem key={1} value={"StateOfCharge"}>StateOfCharge</MenuItem>
              <MenuItem key={2} value={"ModuleVoltage"}>ModuleVoltage</MenuItem>
              <MenuItem key={3} value={"ActualTorque"}>ActualTorque</MenuItem>
              <MenuItem key={4} value={"SystemPowerMode"}>SystemPowerMode</MenuItem>
              <MenuItem key={5} value={"HVBatteryISense"}>HVBatteryISense</MenuItem>
              <MenuItem key={6} value={"HVBatteryVoltage"}>HVBatteryVoltage</MenuItem>
              <MenuItem key={7} value={"RunHours"}>RunHours</MenuItem>
              <MenuItem key={8} value={"OffBoardHVChargeVehicleStat"}>OffBoardHVChargeVehicleStat</MenuItem>
              <MenuItem key={9} value={"Map"}>Map</MenuItem>
            </Select>
          </FormControl>
        </div>
        {
          (chart === "") ? "" :
            <div className="">
              {(chart === "Map") ? <MyMap machine={machineId} date={date} /> : <Graph chartType={chart} machine={machineId} date={date} />}
            </div>
        }
      </div>
      {/* Desktop */}
      <div className="hidden md:flex justify-center gap-10">
          <MachineList machineList={machineList} machineId={machineId} setMachineId={setMachineId} />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              label="Start Date"
              inputFormat="MM/dd/yyyy"
              value={date}
              minDate={new Date('12-22-21')}
              maxDate={new Date()}
              onChange={handleDateChange}
              onAccept={handleSubmit}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <Button sx={{ alignSelf: "center" }} variant="contained" onClick={back12Hr}>{`< Prev 12hr`}</Button>
          <Button sx={{ alignSelf: "center" }} variant="contained" onClick={forward12Hr} >{`Next 12hr >`}</Button>
      </div>
      <div className="hidden md:flex justify-center mt-4 mb-6">
        <ToggleButtonGroup color="primary" value={selectedCharts} onChange={updateSelectedCharts} >
          <ToggleButton value="StateOfCharge">State Of Charge</ToggleButton>
          <ToggleButton value="ModuleVoltage">Module Voltage</ToggleButton>
          <ToggleButton value="ActualTorque">Actual Torque</ToggleButton>
          <ToggleButton value="SystemPowerMode">System Power Mode</ToggleButton>
          <ToggleButton value="HVBatteryISense">HV Battery ISense</ToggleButton>
          <ToggleButton value="HVBatteryVoltage">HV Battery Voltage</ToggleButton>
          <ToggleButton value="RunHours">RunHours</ToggleButton>
          <ToggleButton value="OffBoardHVChargeVehicleStat">Off Board HV Charge Vehicle Stat</ToggleButton>
          <ToggleButton value="Map">Map</ToggleButton>
        </ToggleButtonGroup>
      </div>

      <Box sx={{ display: "grid", gridTemplateRows: "50vh 50vh", gridTemplateColumns: "48vw 48vw", columnGap: "1vw", rowGap: "1vh", margin: "1vw" }}>
        {selectedCharts?.map((el, idx, arr) => {
          let g = "1/1/3/3"
          if (arr.length === 2) g = `1/${idx + 1}/2/${(idx === 0) ? 2 : 3}`
          if (arr.length > 2) g = `${(idx === 0 || idx === 1) ? 1 : 2}/${(idx === 0 || idx === 2) ? 1 : 2}/${(idx === 0 || idx === 1) ? 2 : 3}/${(idx === 0 || idx === 2) ? 2 : 3}`
          const s = (arr.length === 1) ? "center" : "auto"
          //console.log(el)
          return <Box key={idx} sx={{ gridArea: g, placeSelf: s, position: "relative", borderStyle: "solid", borderWidth: "2px", padding: "4px" }}>
            {(el === "Map") ? <MyMap machine={machineId} date={date} /> : <Graph chartType={el} machine={machineId} date={date} />}
          </Box>
        })}
      </Box>
    </>
  )
}

export default DashboardPage