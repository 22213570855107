import React, { useEffect, useState } from "react";

import "./App.css";



import moment from "moment-timezone";
import VehicleDataGrid from "./VehicleDataGrid";
import FleetMap from "./FleetMap";
import CurrentTime from "./CurrentTime";
import AlertsDataGrid from "./AlertsDataGrid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff, faBatteryFull, faHourglass, faBatteryThreeQuarters, faBatteryQuarter, faBolt, faClock } from '@fortawesome/free-solid-svg-icons'
import AlertsCounter from "./AlertCounter";
import SideNav from "./SideNav";
import { getDemoData } from "../api/getDemoData";

const machineNames = {
  'tel1999pwbbaafrodm3ota2': "UPS",
  'tel1999mabkaafrodm3ota2': "Bench Unit 1",
  'tel1999jgbaaafrodm3ota2': "Bench Unit 2"
}
// The real One UPS MA 2728
let fleetData = [
  { id: 1, power: 'On', vehicle: 'MA U7P2S8', SOC: 80, runningHours: 35, coord: [38.173204, -85.731437] },
  { id: 2, power: 'On', vehicle: 'M1A JB3985', SOC: 70, runningHours: 42, coord: [38.163254, -85.741417] },
  { id: 3, power: 'Off', vehicle: 'MA AC1278', SOC: 44, runningHours: 45, coord: [38.163284, -85.731437] },
  { id: 4, power: 'On', vehicle: 'T200', SOC: 98, runningHours: 16, coord: [38.173284, -85.741437] },
  { id: 5, power: 'Off', vehicle: 'M1A PT7844', SOC: 53, runningHours: 45, coord: [38.170284, -85.738437] },
  { id: 6, power: 'Off', vehicle: 'MA 9820', SOC: 92, runningHours: 150, coord: [38.170284, -85.734437] },
  { id: 7, power: 'On', vehicle: 'MA 3321', SOC: 30, runningHours: 44, coord: [38.170284, -85.740437] },
  { id: 8, power: 'On', vehicle: 'MA 5647', SOC: 59, runningHours: 36, coord: [38.175284, -85.740437] },
];

const alerts = [
  // { id: 1, alert: true, vehicle: "UPS MA 9820", message: "RESSTemperatureInhibit", startTime: "3/9/22 1:45 AM", endTime: null },
  { id: 2, alert: false, vehicle: "MA 3985", message: "PEVShiftPosition", startTime: "3/9/22 2:33 AM", endTime: "3/9/22 7:45 AM" },
  { id: 3, alert: true, vehicle: "MA 1278", message: "ChargerConnectorLockFault", startTime: '3/9/22 8:45 AM', endTime: null },
  // { id: 4, alert: true, vehicle: "UPS MA 9820", message: "PEVRESSMalfunction", startTime: '3/9/22 7:22 AM', endTime: null },
  // { id: 5, alert: false, vehicle: "UPS MA 4211", message: "ChargingCurrentDifferential", startTime: '3/9/22 6:42 AM', endTime: "3/9/22 9:00 AM" },
  // { id: 6, alert: true, vehicle: "UPS MA 9820", message: "ChargVoltageOutofRange", startTime: '3/9/22 5:23 AM', endTime: null },
  // { id: 7, alert: false, vehicle: "UPS MA 5647", message: "ChargSystemIncompatibility", startTime: '3/9/22 4:55 AM', endTime: "3/9/22 8:22 AM" },
  // { id: 8, alert: false, vehicle: "UPS MA 3985", message: "PEVRESSMalfunction", startTime: '3/9/22 4:35 AM', endTime: "3/9/22 7:13 AM" },
]

const titleObj = {
  worldPortSDF: 'Worldport (SDF)',
  westernHubONT: 'Western Hub (ONT)',
  torontoPearsonYYZ: 'Toronto Pearson (YYZ)',
  vancouverYVR: 'Vancouver International (YVR)',
  atlantaATL: 'Atlanta-Hartsfield (ATL)'
}

const Dashboard2Page = ({ setPage, page, machineIdx }) => {
  const [selectedVehicle, setSelectedVehicle] = useState(fleetData[0])
  const [site, setSite] = useState("worldPortSDF")

  // useEffect(() => {
  //   const utcDateString = moment().utc().format()
  //   getDemoData(utcDateString).then((result) => {
  //     if (result?.length === 0) return
  //     fleetData[0].SOC = result.SOC
  //     fleetData[0].runningHours = parseFloat(result.RunHours).toFixed(2)
  //     setSelectedVehicle(fleetData[0])
  //   })
  // }, [])

  const powerColor = (selectedVehicle.power === "On") ? "green" : "red"

  const { SOC } = selectedVehicle
  let ignitionIcon;
  let ignitionColor;
  if (SOC > 90) { ignitionIcon = faBatteryFull; ignitionColor = 'green' }
  else if (SOC > 75) { ignitionIcon = faBatteryThreeQuarters; ignitionColor = 'green' }
  else if (SOC > 50) { ignitionIcon = faBolt; ignitionColor = 'black' }
  else if (SOC > 0) { ignitionIcon = faBatteryQuarter; ignitionColor = 'red' }

  return (
    <div className="grid grid-cols-[1fr_6fr] ">
      <SideNav site={site} setSite={setSite} setPage={setPage}/>


        <div className="grid grid-cols-[3fr_2fr] grid-rows-[0.25fr_0.5fr_1fr_1fr_0.5fr] h-[94vh]">

          <div className="grid row-span-1 bg-[#3B4761] col-span-2 place-items-left">
            <p className="text-white font-semibold text-2xl pl-10 pt-5 ">{titleObj[site]}</p>
          </div>
          
          <div className="grid row-span-3 bg-black">
            <FleetMap site={site} selectedVehicle={selectedVehicle} setSelectedVehicle={setSelectedVehicle} fleetData={fleetData} />
          </div>
          
          <div className="  grid row-start-5 row-end-5 place-items-left text-xl font-semibold grid-rows-[0.5fr_1fr]">
            
            <div className="place-items-center row-span-1 bg-[#dedede] pl-10 pt-3 pb-3">
              <p className="text-[darkBlue] font-bold text-3xl">{selectedVehicle.vehicle}</p>
            </div>
            
            <div className="place-items-center grid grid-cols-5   pl-10 bg-[#F2F2F2]">

                <div className="flex items-center text-base justify-self-start">
                  <FontAwesomeIcon color={powerColor} icon={faPowerOff} />
                  <p className="ml-1" style={{ color: powerColor }}>{selectedVehicle.power}</p>
                </div>

                <div className="flex items-center text-base justify-self-start">
                  <FontAwesomeIcon icon={faHourglass} />
                  <p className="text-black-500 ml-1">{selectedVehicle.runningHours} hrs</p>
                </div>
                <div className="flex items-center text-base justify-self-start">
                  <FontAwesomeIcon color={ignitionColor} icon={ignitionIcon} />
                  <p className="m-1" style={{ color: ignitionColor }}>{selectedVehicle.SOC}%</p>
                </div>
                <div className="flex items-center text-base justify-self-start">
                  <AlertsCounter selectedVehicle={selectedVehicle.vehicle} alerts={alerts}/>
                </div>
                <div className="flex items-center text-base justify-self-start">
                <FontAwesomeIcon icon={faClock} className="mr-1" />
                  <CurrentTime className= "m-1 text-black" page={page}/>
                </div>

            </div>

          </div>


          
          <div className="row-span-2 flex flex-col" >
            <div className="h-12 w-full bg-[#dedede]"><h2 className="text-center mt-2 text-xl text-darkBlue font-semibold">Vehicle Infomation</h2></div>
            <VehicleDataGrid fleetData={fleetData} setPage={setPage} machineIdx={machineIdx} />
          </div>
         
          <div className="row-span-2 flex flex-col">
            <div className="h-12 w-full bg-[#dedede]"><h2 className="text-center mt-2 text-xl text-darkBlue font-semibold">DTC Alerts</h2></div>
            <AlertsDataGrid alerts={alerts}/>
          </div>
        </div>

    </div>
  )
}

export default Dashboard2Page
