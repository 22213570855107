import React, { useState, useEffect } from "react";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import FastRewindSharpIcon from '@mui/icons-material/FastRewindSharp';
import FastForwardSharpIcon from '@mui/icons-material/FastForwardSharp';
import moment from "moment-timezone";
import TextField from '@mui/material/TextField';
import { IconButton, Slider } from "@mui/material";
import RestartAltSharpIcon from '@mui/icons-material/RestartAltSharp';
import PlayArrowSharpIcon from '@mui/icons-material/PlayArrowSharp';
import StopSharpIcon from '@mui/icons-material/StopSharp';
import PauseSharpIcon from '@mui/icons-material/PauseSharp';
import { CircularProgress } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MyMap from "./MyMap";
import { getVehicleInfo } from "../api/getVehicleInfo";
import OndemandVideoSharpIcon from '@mui/icons-material/OndemandVideoSharp';
import MyMap2 from "./MyMap2";
import VehicleDataCard from "./VehicleDataCard";
import SOCChart from "./SOCChart";
import ExtraDataCard from "./ExtraDataCard";
import MySlider from "./MySlider";
import ExportButtons from "./ExportButtons";

const defaultPlayBack = { isEnabled: false, isPlaying: false }

const machineNames = {
    "tel1999pwbbaafrodm3ota2": 'MA U7P2S8',
    "tel1999jwaeaafrodm3ota2": 'MA AC1278',
    "tel1999jgbaaafrodm3ota2": 'M1A JB3985',
    "tel1999mabkaafrodm3ota2": 'M1A PT7844',
    "test": 'T200'
}


const VehiclePage = ({ setPage, machine }) => {
    const [date, setDate] = useState(moment().startOf('day'));
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    //console.print(machine)

    const [playBack, setPlayBack] = useState(defaultPlayBack)
    const [dataPoint, setDataPoint] = useState(0)


    useEffect(() => {
        setIsLoading(true)
        setPlayBack(defaultPlayBack)

        const utcDateString = moment(date).utc().format()
        getVehicleInfo(utcDateString, machine).then((result) => {
            console.dir(result)
            setData(result)
        }).then(() => setDataPoint(1)).finally(() => setIsLoading(false))
    }, [date, machine])

    const handleDateChange = (newValue) => {
        console.log("Date Changed")
        setDate(moment(newValue).startOf('day'))
    }

    const handleDateClick = (which) => {
        let newDate
        if (which === 'forward') {
            newDate = moment(date).add(1, 'day')
            if (newDate > moment(new Date())) return
        } else {
            newDate = moment(date).subtract(1, 'day')
            if (newDate < moment(new Date('12-22-21'))) return
        }
        setDate(newDate)
    }



    return (
        <div className="grid grid-cols-[8fr]">
           
            <div className="flex flex-col">
                <div className="flex items-center justify-between  bg-[#3B4761] w-full pt-3 pb-3">
                    <div className="flex items-center">
                        <IconButton><ArrowBackIcon sx={{ color: "white" }} onClick={() => setPage("Dashboard2")} /></IconButton>
                        <p className="ml-6 text-[16px] text-white font-[Arial] font-bold">{machineNames[machine]}</p>

                    </div>
                   
                </div>
                <div className="bg-[#F2F2F2] w-full flex  items-center pt-5 pb-5 justify-center">
                    <IconButton onClick={() => handleDateClick("backward")}><FastRewindSharpIcon sx={{ color: "#3B4761" }} /></IconButton>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            disableHighlightToday
                            label="Choose Date"
                            inputFormat="MM/dd/yyyy"
                            value={date}
                            minDate={new Date('12-22-21')}
                            maxDate={new Date()}
                            onChange={handleDateChange}
                            renderInput={(params) => {
                                return <TextField sx={{ width: '150px', input: { color: "#3B4761" }, svg: { color: "#3B4761" }, label: { color: "#3B4761" } }} {...params} />
                            }}
                        />
                    </LocalizationProvider>
                    <IconButton onClick={() => handleDateClick("forward")}><FastForwardSharpIcon sx={{ color: "#3B4761" }} /></IconButton>
                    {(playBack.isEnabled) ?
                        <div className="flex flex-col justify-center items-center w-[40%] ml-[100px] mr-[100px] h-14">
                            <p className="text-[#3B4761]">{moment(data[dataPoint].Time).format("lll")}</p>
                            <MySlider data={data} length={data.length} setDataPoint={setDataPoint} isPlaying={playBack.isPlaying} dataPoint={dataPoint} />
                            <div className="flex">
                                {(playBack.isPlaying) ?
                                    <IconButton onClick={() => setPlayBack({ ...playBack, isPlaying: false })}><PauseSharpIcon sx={{ color: "#3B4761" }} /></IconButton>
                                    :
                                    <IconButton onClick={() => setPlayBack({ ...playBack, isPlaying: true })}><PlayArrowSharpIcon sx={{ color: "#3B4761" }} /></IconButton>
                                }

                                <IconButton onClick={() => setPlayBack({ ...playBack, isPlaying: false, isEnabled: false })}><StopSharpIcon sx={{ color: "#3B4761" }} /></IconButton>
                            </div>
                        </div>
                        :
                        <div className="ml-[100px] mr-[100px] flex flex-col h-14">
                            <p className="text-[#3B4761]">Start PlayBack</p>
                            <IconButton onClick={() => setPlayBack({ ...playBack, isEnabled: true })}><OndemandVideoSharpIcon sx={{ color: "#3B4761" }} /></IconButton>
                        </div>
                    }
                     <ExportButtons date={date} machine={machine} />
                </div>

                {(isLoading) ?
                    <div className="flex flex-col gap-5 items-center justify-center h-[79vh] w-full bg-gray-400 ">
                        <p className="text-black text-2xl">Loading....</p>
                        <CircularProgress size={60} sx={{ color: "blue" }} />
                    </div>
                    : (data.length === 0) ? 
                    <div className="flex flex-col gap-5 items-center justify-center h-[79vh] w-full bg-gray-400 ">
                        <p className="text-black text-2xl">No Data</p>                        
                    </div>
                    :
                    <div className="grid grid-cols-[2fr_1.3fr] grid-rows-2 h-[77vh]">
                        <div className="h-full row-span-4">
                            <MyMap2 data={data} playBack={playBack} setPlayBack={setPlayBack} dataPoint={dataPoint} setDataPoint={setDataPoint} />
                        </div>
                        <SOCChart data={data} dataPoint={dataPoint} row-span-2/>
                        <ExtraDataCard data={data} dataPoint={dataPoint} machine={machine} row-span-2/>
                        {/* <VehicleDataCard chartName={"StateOfCharge"} data={data} title={"State Of Charge"} dataPoint={dataPoint} dataString={data[dataPoint].StateOfCharge + '%'}/> */}
                        {/* <VehicleDataCard chartName={"BatteryVoltage"} data={data} title={"Battery Voltage"} dataPoint={dataPoint} dataString={data[dataPoint].BatteryVoltage + ' V'}/> */}
                        {/* <VehicleDataCard chartName={"BatteryTemp"} data={data} title={"Battery Temperature"} dataPoint={dataPoint} dataString={data[dataPoint].BatteryTemp + ' C'}/> */}
                        {/* <VehicleDataCard chartName={"MotorOilTemp"} data={data} title={"Motor Oil Temp"} dataPoint={dataPoint} dataString={data[dataPoint].MotorOilTemp + ' C'}/>
                        
                        <VehicleDataCard chartName={"MotorSpeed"} data={data} title={"Motor Speed"} dataPoint={dataPoint} dataString={data[dataPoint].MotorSpeed + ' RPM'}/> */}

                        {/* <VehicleDataCard chartName={"AmbientTemp"} data={data} title={"Ambient Temperature"} dataPoint={dataPoint} dataString={data[dataPoint].AmbientTemp + ' C'}/> */}
                    </div>
                }

            </div>
        </div>
    )
}

export default VehiclePage
