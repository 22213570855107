import axios from "axios";

export async function logIn(username, password) {
  try {
    const result = await axios.post("api/login", {username, password});
    console.dir(result);
    return result.data;
  } catch (error) {
    console.log("Trouble Logging In");
    console.dir(error);
    return error.response.data
    // test returning the Error message from an axios request
  }
}