import React, { useState, useRef } from "react";
import { IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from "@mui/material";
import { saveAs } from "file-saver";
import moment from "moment-timezone";
import L, { map } from 'leaflet'
import { MapContainer, TileLayer, MapConsumer, CircleMarker, Popup } from 'react-leaflet'

let bounds = null

const KozakGPSPage = ({ setPage }) => {
    const [file, setFile] = useState(null)
    const [mapData, setMapData] = useState([])

    const checkBox = useRef(null)


    const showFile = async () => {
        if (file === null) return
        const reader = new FileReader()
        reader.onload = async (e) => {
            const text = (e.target.result)
            const textArray = text.split(/\r?\n/)

            let count = 0
            let timeStamp = null
            let latitude = null
            let longitude = null
            let gpsCSV = "TimeStamp,Latitude,Longitude\r\n"
            const gpsArray = []

            for (let i = 0; i < textArray.length; i++) {
                const line = textArray[i]
                for (let j = 0; j < line.length; j++) {
                    if (line.startsWith("  \"ts\"")) {
                        //reset
                        timeStamp = null
                        latitude = null
                        longitude = null

                        let ts = line.substring(9)
                        ts = ts.slice(0, ts.length - 2)
                        timeStamp = ts
                    }

                    if (line.startsWith("  \"0120\"")) {
                        let lat = line.substring(10)
                        lat = lat.slice(0, lat.length - 1)
                        lat = parseInt(lat)
                        lat = lat / 1000000
                        lat = lat.toFixed(6)

                        latitude = lat
                    }

                    if (line.startsWith("  \"0121\"")) {
                        let lon = line.substring(10)
                        lon = lon.slice(0, lon.length - 1)
                        lon = parseInt(lon)
                        lon = lon / 1000000
                        lon = lon.toFixed(6)

                        longitude = lon

                        if (timeStamp && latitude && longitude) {
                            gpsCSV += `${timeStamp},${latitude},${longitude}\r\n`
                            gpsArray.push({ timeStamp, latitude, longitude })
                        }
                    }

                }
            }
            if (checkBox.current.checked) {
                const blob = new Blob([gpsCSV], { type: "text/plain;charset=utf-8" })
                saveAs(blob, "gps.csv")
            }
            setMapData(gpsArray)

        }

        reader.readAsText(file)
    }

    return (
        <>
            <div className="flex items-center justify-between  bg-[#3B4761] w-full pt-3 pb-3">
                <div className="flex items-center">
                    <IconButton><ArrowBackIcon sx={{ color: "white" }} onClick={() => setPage("Dashboard2")} /></IconButton>
                    <p className="ml-6 text-[16px] text-white font-[Arial] font-bold">Kozak's GPS Page</p>
                </div>
            </div>
            <div className="flex flex-col items-center   h-screen w-screen">
                <input className="my-2 text-center" type={"file"} onChange={(event) => setFile(event.target.files[0])} />
                <div className="flex flex-row justify-between">
                    <p>Download CSV file</p>
                    <input className="ml-3" type={"checkbox"} ref={checkBox} defaultChecked/>
                </div>
                <Button style={{ marginTop: 2, marginBottom: 6 }} onClick={showFile} variant="contained">Upload file</Button>
                <div className="w-[70%] h-[73%]">
                    <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={true}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />

                        {mapData.map((el, idx, arr) => {
                            return <CircleMarker pathOptions={{ color: "blue" }} center={[el.latitude, el.longitude]} key={idx} radius={2}>
                                <Popup>Time: {moment.utc(el.timeStamp).format("lll")}</Popup>
                            </CircleMarker>
                        })}
                        <MapConsumer>
                            {(map) => {
                                if (mapData.length === 0) return null
                                const coordArray = mapData.reduce((prev, curr) => {
                                    prev.push([curr.latitude, curr.longitude])
                                    return prev
                                }, [])
                                //console.dir(coordArray)
                                const latLon = L.latLngBounds(coordArray)
                                bounds = latLon
                                //console.log("LatLon", latLon)
                                map.fitBounds(latLon)
                                return null
                            }}
                        </MapConsumer>
                    </MapContainer>
                </div>
            </div>
        </>
    )
}

export default KozakGPSPage