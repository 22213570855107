import axios from "axios";

export async function getMachines() {
  const token = localStorage.getItem("token")
    try {
      //console.log('getting machines')
      const result = await axios.get("api/Machines", {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      //console.dir(result);
      return result.data;
    } catch (error) {
      console.log("Trouble fetching Machine Ids");
      console.dir(error);
    }
  }