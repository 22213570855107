import axios from "axios";

export async function getFile(startDate, endDate, machine, isTSV) {
  const token = localStorage.getItem("token")
    if (startDate === null || endDate === null) return
    try {
      const result = await axios.get(`api/File?startDate=${startDate}&endDate=${endDate}&machine=${machine}&isTSV=${isTSV}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },  
        responseType: 'text'
          
      });
      //console.dir(result);
      //console.log(result.data);
      return result.data;
    } catch (error) {
      console.log("Trouble fetching Machine Ids");
      console.dir(error);
    }
  }