import React, { useState, useEffect, useMemo } from "react";

const calcMinMax = (data) => {
    console.log("Calculating Min and Max")
    //MotorOilTemp
    let minOilTemp = Infinity
    let maxOilTemp = -Infinity
    //Odometer
    let minOdometer = Infinity
    let maxOdometer = -Infinity
    //Motor Speed
    let minMotorSpeed = Infinity
    let maxMotorSpeed = -Infinity
    //Battery Temp
    let minBatteryTemp = Infinity
    let maxBatteryTemp = -Infinity
    //HVBattery
    let minHVBattery = Infinity
    let maxHVBattery = -Infinity
    //HVBatteryAmps
    let minHVBatteryAmps = Infinity
    let maxHVBatteryAmps = -Infinity
    //RunHours
    data.forEach((el, idx) => {
        const motorOilTemp = parseFloat(el.MotorOilTemp)
        const odometer = parseFloat(el.Odometer)
        const motorSpeed = parseFloat(el.MotorSpeed)
        const batteryTemp = parseFloat(el.BatteryTemp)
        const hVBattery = parseFloat(el.HVBattery)
        const hVBatteryAmps = parseFloat(el.HVBatteryAmps)
        //checking for mins
        if (motorOilTemp < minOilTemp && el.MotorOilTemp != null) minOilTemp = motorOilTemp
        if (odometer < minOdometer) minOdometer = odometer 
        if (motorSpeed < minMotorSpeed) minMotorSpeed = motorSpeed
        if (batteryTemp < minBatteryTemp && batteryTemp !== 0) minBatteryTemp = batteryTemp
        if (hVBattery < minHVBattery && hVBattery !== 0) minHVBattery = hVBattery
        if (hVBatteryAmps < minHVBatteryAmps) minHVBatteryAmps = hVBatteryAmps

        //checking for max
        if (motorOilTemp > maxOilTemp && el.MotorOilTemp != null) maxOilTemp = motorOilTemp
        if (odometer > maxOdometer) maxOdometer = odometer
        if (motorSpeed > maxMotorSpeed) maxMotorSpeed = motorSpeed
        if (batteryTemp > maxBatteryTemp) maxBatteryTemp = batteryTemp
        if (hVBattery > maxHVBattery) maxHVBattery = hVBattery
        if (hVBatteryAmps > maxHVBatteryAmps) maxHVBatteryAmps = hVBatteryAmps
    })
    return {
        minOilTemp,
        maxOilTemp,

        minOdometer,
        maxOdometer,

        minMotorSpeed,
        maxMotorSpeed,

        minBatteryTemp,
        maxBatteryTemp,

        minHVBattery,
        maxHVBattery,

        minHVBatteryAmps,
        maxHVBatteryAmps
    }
}

const getLeverPosition = (value) => {
    if (value === 2) return "Reverse"
    if (value === 1) return "Forward"
    if (value === 0) return "Neutral"
    if (value === 3) return "State Not Reached"
}

const getSystemPowerMode = (value) => {
    if (value === 0) return "Off"
    if (value === 1) return "Accessory"
    if (value === 2) return "Run"
    if (value === 3) return "Crank Request"

}

const ExtraDataCard = ({ data, dataPoint, machine }) => {

    const minMaxObj = useMemo(() => calcMinMax(data), [data])

    //console.dir(minMaxObj)


    return (
        <div className="flex flex-col text-white">
            <div className="w-full bg-[#4F5B75] ">
                <h2 className="text-center font-semibold text-xl text-white pt-2 pb-2">Additional Data</h2>
            </div>
            <div className="grid grid-cols-3 grid-rows-2 gap-1 mt-1 text-black">
                {/* Motor Oil Temp */}
                {/* <div className="flex flex-col items-center ">
                    <div className="bg-[#DEDEDE] w-full p-2"><p className="text-center font-semibold">Motor Oil Temp</p></div>
                    <div className="flex justify-around w-full bg-[#F2F2F2] p-5">
                        <div className="flex flex-col text-center ">
                            <p className="underline">Min</p>
                            <p>{minMaxObj.minOilTemp} &deg;C</p>
                        </div>
                        <div className="flex flex-col text-center  text-blue-700 font-semibold">
                            <p className="underline">Current</p>
                            <p>{data[dataPoint].MotorOilTemp} &deg;C</p>
                        </div>
                        <div className="flex flex-col text-center">
                            <p className="underline">Max</p>
                            <p>{minMaxObj.maxOilTemp} &deg;C</p>
                        </div>
                        WHERE RecordTime between '2023-03-21 14:00:00' AND '2023-03-21 14:30:00';
                    </div>
                </div> */}
                {/* Odometer */}
                <div className="flex flex-col items-center ">
                    <div className="bg-[#DEDEDE] w-full p-2"><p className="text-center font-semibold">Odometer</p></div>
                    <div className="flex justify-around w-full bg-[#F2F2F2] p-5">
                        <div className="flex flex-col text-center ">
                            <p className="underline">Min</p>
                            <p>{minMaxObj.minOdometer} km</p>
                        </div>
                        <div className="flex flex-col text-center  text-blue-700 font-semibold">
                            <p className="underline">Current</p>
                            <p>{data[dataPoint].Odometer} km</p>
                        </div>
                        <div className="flex flex-col text-center">
                            <p className="underline">Max</p>
                            <p>{minMaxObj.maxOdometer} km</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center">
                    <div className="bg-[#DEDEDE] w-full p-2"><p className="text-center font-semibold">Motor Speed</p></div>
                    <div className="flex justify-around w-full bg-[#F2F2F2] p-5">
                        <div className="flex flex-col text-center">
                            <p className="underline">Min</p>
                            <p>{minMaxObj.minMotorSpeed} RPM</p>
                        </div>
                        <div className="flex flex-col text-center  text-blue-700 font-semibold">
                            <p className="underline">Current</p>
                            <p>{parseFloat(data[dataPoint].MotorSpeed)} RPM</p>
                        </div>
                        <div className="flex flex-col text-center">
                            <p className="underline">Max</p>
                            <p>{minMaxObj.maxMotorSpeed} RPM</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center ">
                    <div className="bg-[#DEDEDE] w-full p-2"><p className="text-center font-semibold">{(machine === "test") ? "Accelerator Position" : "Battery Temperature"}</p></div>
                    <div className="flex justify-around w-full bg-[#F2F2F2] p-5">
                        <div className="flex flex-col text-center">
                            <p className="underline">Min</p>
                            {(machine === "test") ? <p>0</p> : <p>{minMaxObj.minBatteryTemp} &deg;C</p>}
                            {/* <p>{minMaxObj.minBatteryTemp} &deg;C</p> */}
                        </div>
                        <div className="flex flex-col text-center text-blue-700 font-semibold">
                            <p className="underline">Current</p>
                            {(machine === "test") ? <p>{data[dataPoint].BatteryTemp}</p> : <p>{data[dataPoint].BatteryTemp} &deg;C</p>}
                            {/* <p>{data[dataPoint].BatteryTemp} &deg;C</p> */}
                        </div>
                        <div className="flex flex-col text-center">
                            <p className="underline">Max</p>
                            {(machine === "test") ? <p>{minMaxObj.maxBatteryTemp}</p> : <p>{minMaxObj.maxBatteryTemp} &deg;C</p>}
                            {/* <p>{minMaxObj.maxBatteryTemp} &deg;C</p> */}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col items-center ">
                    <div className="bg-[#DEDEDE] w-full p-2"><p className="text-center font-semibold">{(machine === "test") ? "Low Voltage Battery (V)" : "High Voltage Battery (V)"}</p></div>
                    <div className="flex justify-around w-full bg-[#F2F2F2] p-5">
                        <div className="flex flex-col text-center">
                            <p className="underline">Min</p>
                            <p>{minMaxObj.minHVBattery} V</p>
                        </div>
                        <div className="flex flex-col text-center text-blue-700 font-semibold">
                            <p className="underline">Current</p>
                            <p>{data[dataPoint].HVBattery} V</p>
                        </div>
                        <div className="flex flex-col text-center">
                            <p className="underline">Max</p>
                            <p>{minMaxObj.maxHVBattery} V</p>
                        </div>
                    </div>
                </div>
                {(machine === "test")
                    ?
                    <div className="flex flex-col items-center ">
                        <div className="bg-[#DEDEDE] w-full p-2"><p className="text-center font-semibold">System Power Mode</p></div>
                        <div className="flex justify-around w-full bg-[#F2F2F2] p-5">
                            <div className="flex flex-col text-center text-blue-700 font-semibold">
                                <p className="underline">Current</p>
                                <p>{getSystemPowerMode(data[dataPoint].systemPowerMode)}</p>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="flex flex-col items-center ">
                        <div className="bg-[#DEDEDE] w-full p-2"><p className="text-center font-semibold">High Voltage Battery (A)</p></div>
                        <div className="flex justify-around w-full bg-[#F2F2F2] p-5">
                            <div className="flex flex-col text-center">
                                <p className="underline">Min</p>
                                <p>{minMaxObj.minHVBatteryAmps} A</p>
                            </div>
                            <div className="flex flex-col text-center text-blue-700 font-semibold">
                                <p className="underline">Current</p>
                                <p>{data[dataPoint].HVBatteryAmps} A</p>
                            </div>
                            <div className="flex flex-col text-center">
                                <p className="underline">Max</p>
                                <p>{minMaxObj.maxHVBatteryAmps} A</p>
                            </div>
                        </div>
                    </div>
                }
                {/* <div className="flex flex-col items-center ">
                    <div className="bg-[#DEDEDE] w-full p-2"><p className="text-center font-semibold">High Voltage Battery (A)</p></div>
                    <div className="flex justify-around w-full bg-[#F2F2F2] p-5">
                        <div className="flex flex-col text-center">
                            <p className="underline">Min</p>
                            <p>{minMaxObj.minHVBatteryAmps} A</p>
                        </div>
                        <div className="flex flex-col text-center text-blue-700 font-semibold">
                            <p className="underline">Current</p>
                            <p>{data[dataPoint].HVBatteryAmps} A</p>
                        </div>
                        <div className="flex flex-col text-center">
                            <p className="underline">Max</p>
                            <p>{minMaxObj.maxHVBatteryAmps} A</p>
                        </div>
                    </div>
                </div> */}
                <div className="flex flex-col items-center ">
                    <div className="bg-[#DEDEDE] w-full p-2"><p className="text-center font-semibold">{(machine === "test") ? "Lever Position" : "Run Hours"}</p></div>
                    <div className="flex justify-around w-full bg-[#F2F2F2] p-5">
                        <div className="flex flex-col text-center text-blue-700 font-semibold">
                            <p className="underline">Current</p>
                            {(machine === "test") ? <p>{getLeverPosition(parseInt(data[dataPoint].leverPosition))} </p> : <p>{parseInt(data[dataPoint].Hours)} Hrs</p>}
                            {/* <p>{parseInt(data[dataPoint].Hours)} Hrs</p> */}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExtraDataCard