import React, { useState, useEffect } from "react";
import { Slider } from "@mui/material";
import moment from "moment-timezone";

const MySlider =({length, setDataPoint, isPlaying, dataPoint, data}) => {
    const [tempSliderPoint, setTempSliderPoint] = useState(1)

    useEffect(() => {
        setTempSliderPoint(dataPoint)
    }, [dataPoint])

    const handleSliderChange = (event, value) => {
        setTempSliderPoint(value)
    }

    const handleSliderCommit = (event, value) => {
        setDataPoint(value)
    }

    return(
        <Slider disabled={isPlaying} onChangeCommitted={handleSliderCommit} onChange={handleSliderChange} defaultValue={0} max={length - 1} value={tempSliderPoint} aria-label="Default" 
            valueLabelFormat={(x) => `${moment(data[x].Time).format("lll")}`}
            valueLabelDisplay="auto" />
    )
}

export default MySlider