import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const machineNames = {
    'tel1999pwbbaafrodm3ota2': "UPS",
    'tel1999mabkaafrodm3ota2': "Bench Unit 1",
    'tel1999jgbaaafrodm3ota2': "Bench Unit 2"
}

const MachineList = ({machineList, machineId, setMachineId }) => {

    //This sets the dropdown to the 1st item in the list. Steps: Page loads machine list is empty (return), useEffect on App.js fetches the list from server, 
    //machineList is updated, function below is reran
    useEffect(() => {
        if (machineList.length === 0) return
        setMachineId(machineList[1])
        //machine = machineList[0]
      }, [machineList])

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
                <InputLabel id="machine-id-label">Machine</InputLabel>
                <Select
                    labelId="machine-id-label"
                    id="demo-simple-select"
                    value={machineId}
                    label="Machine ID"
                    onChange={(event) => setMachineId(event.target.value)}
                >
                    {machineList.map(el => <MenuItem key={el} value={el}>{machineNames[el]}</MenuItem>)}
                </Select>
            </FormControl>
        </Box>
    )
}

export default MachineList