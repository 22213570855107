import React, { useState } from "react";

const SideNav = ({site, setSite, setPage}) => {

    //"#8ED0FF"
    return (
        <div className="bg-[#313D57] h-full flex flex-col items-left gap-2 p-6 pt-10">
            <p className="text-white font-semibold text-lg">Sites</p>
            <p onClick={() => setSite('worldPortSDF')} style={{color: (site === "worldPortSDF") ? "#8ED0FF" : "white"}} className=" cursor-pointer hover:text-linkBlue">Worldport (SDF)</p>
            <p onClick={() => setSite('westernHubONT')} style={{color: (site === "westernHubONT") ? "#8ED0FF" : "white"}} className="text-white cursor-pointer hover:text-linkBlue">Western Hub (ONT)</p>
            <p onClick={() => setSite('torontoPearsonYYZ')} style={{color: (site === "torontoPearsonYYZ") ? "#8ED0FF" : "white"}} className="text-white cursor-pointer hover:text-linkBlue">Toronto Pearson (YYZ)</p>
            <p onClick={() => setSite('vancouverYVR')} style={{color: (site === "vancouverYVR") ? "#8ED0FF" : "white"}} className="text-white cursor-pointer hover:text-linkBlue">Vancouver International (YVR)</p>
            <p onClick={() => setSite('atlantaATL')} style={{color: (site === "atlantaATL") ? "#8ED0FF" : "white"}} className="text-white cursor-pointer hover:text-linkBlue">Atlanta-Hartsfield (ATL)</p>
            <p onClick={() => setPage('Kozak_GPS')} style={{color: (site === "atlantaATL") ? "#8ED0FF" : "white"}} className="text-white cursor-pointer hover:text-linkBlue">Kozak's GPS Page</p>
            <p  className="text-white cursor-pointer ">View All</p>
        </div>
    )
}

export default SideNav