import axios from "axios";

export async function getMapData(date, machine) {
  const token = localStorage.getItem("token")
  try {
    console.log("machine type", machine)
    const result = await axios.get("api/Map" + `?startDate=${date}&machine=${machine}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    console.dir(result);
    return result.data;
  } catch (error) {
    console.log("Trouble fetching Map Data");
    console.dir(error);
    // test returning the Error message from an axios request
  }
}