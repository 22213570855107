import React from "react";
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AlertsCounter = ({selectedVehicle, alerts}) => {
    const count = alerts.reduce((acc, el, idx) =>{
        return (el.vehicle === selectedVehicle) ? acc+1 : acc
    }, 0)

    const alertColor = (count !== 0) ? "red" : "black" 

    return (
        <>
            <FontAwesomeIcon color={alertColor} icon={faTriangleExclamation} />
            <p className="ml-1" style={{color: alertColor}}>{count} Active</p>
        </>
    )
}

export default AlertsCounter