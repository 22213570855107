import React, { useState } from "react";
import moment from "moment-timezone";

const CurrentTime = ({page}) => {
    const [time, setTime] = useState(moment().format("LTS"))

    if (page === "Dashboard2") setTimeout(() => setTime(moment().format("LTS")), 1000)

    return <p className="text-black">{time}</p>
}

export default CurrentTime